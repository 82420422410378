import React from 'react'
import { Link } from "gatsby"

import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'
// import StackedTexts from '../../../components/stacked-texts'
import Reports from '../../../components/reports'

const bannerContent = {
  title: "Dive deep with reporting and insights for your business.",
  description: [
    {
      text: "Your Billsby Dashboard collates all of the facts, figures and information you need to run your subscription business in one place - so you can always keep on top of what's going on and how it'll impact your company."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: 'black',
    }
  ],
  sectionPageBanner: true,
  image: require('../../../images/reports-insights.svg'),
  svg: true,
  imageName: 'sreports-insights svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right'
}

const billsbyBriefing = {
  title: "Keep everyone informed with the Billsby Briefing.",
  description: [
    {
      text: "Every day, all the teammates on your account will receive the Billsby Briefing - it'll tell them everything they need to know about how your business performed overnight. So you'll have less questions about performance, and more time to get the job done.",
    }
  ],
  hasImageDescription: true,
  imageDescription: 'BillsbyBriefing.png',
  imageDescriptionName: 'BillsbyBriefing png',
  image: require('../../../images/billsby-briefing.svg'),
  imageName: 'billsby-briefing svg',
  svg: true,
  backgroundColor: '#243F5C',
  textColor: '#FFFFFF',
  imagePosition: 'right'
}

const activitySummary = {
  title: <>The <span className="highlight">activity summary</span> gives you all your numbers in one report.</>,
  list: [
    {
      title: 'Full sales, tax and discounting data',
      description: <>Understand all the figures you need to see business performance at a glance, including month-to-date data.</>,
    },
    {
      title: 'Support for reversals and write-offs',
      description: <>See the full story - not just the positive aspects of your performance - with data on negative transactions, too.</>,
    },
    {
      title: 'Run at any time and export to CSV',
      description: <> Get the data where you need it most with instant exports and real time updates of all your key numbers.</>,
    }
  ],
  image: 'reports-insights-1.png',
  imageName: 'Report and Insights Image',
}

const taxSummary = {
  title: <>The <span className="highlight">tax summary</span> collects your sales tax data the world over.</>,
  list: [
    {
      title: 'All your tax data in one place',
      description: <>If you trade the world over you probably use multiple sales tax solutions in your Billsby account. This report collates all that information together.</>,
    },
    {
      title: 'You pick the reporting period',
      description: <>Match the reporting period to the timescale you're completing forms about, or get summary data for internal reports.</>,
    },
    {
      title: 'Split by country or jurisdiction',
      description: <>You decide the level of granularity you'd like to see your tax data displayed in. We can show you whole countries, or individual tax zones. It's up to you.</>,
    }
  ],

  image: 'reports-insights-2.png',
  imageName: 'Report and Insights Image',
  align: 'right',
}

const exitReasons = {
  title: <>With our <span className="highlight">exit reasons report</span>, demystify your churn.</>,
  list: [
    {
      title: 'Track as many exit reasons as you want',
      description: <>Create the categories that make sense for your product, and we'll ask customers why they left using our <Link to="/product/subscribers/dunning-retention">built-in retention tools.</Link></>,
    },
    {
      title: 'Collect verbatim comments',
      description: <>A category alone doesn't tell you much - so verbatim comments help you dive deeper into customers reasons for leaving.</>,
    },
    {
      title: 'Export to other tools ',
      description: <>Want to perform sentiment analysis, collate exit reasons in a database or just take a backup? Export to CSV at any time.</>,
    }
  ],
  image: 'reports-insights-3.png',
  imageName: 'Report and Insights Image',
  align: 'left',
}

// const behindPaywalls = {
//   image: require('../../../images/support-talk.svg'),
//   imageName: 'billsby-payment',
//   svg: true,
//   stackedTexts : [
//     {
//       title: "Share data and insights with everyone on your team.",
//       list: [
//         {
//           title: '',
//           description: <>Our account management team can help you to get setup with Billsby, answer your implementation questions and point you in the right direction to get started. If you're doing development in house, our developer community, email support, chat support and phone call-backs are included with every plan. </>,
//         },
//         {
//           title: '',
//           description: <> If you need development assistance, we can help with Pro Development Services too - our all-inclusive end-to-end developed for you solution that can integrate Billsby into your existing business and processes at a surprisingly affordable price. Let us know how we can help you to succeed.</>,
//         },
//       ],
//     },

//   ]
// }

const reportsContent = {
  title: "Whatever's happening in your business, there's a report for it",
  description: "Our growing library of reports help keep everyone in your company in the loop, so whatever's happening, you'll be able to get the data and insight you need to understand it, and figure out the next steps.",
  blocks: [ 
    {
      title: "Accounting",
      lists: [
        {
          title: "Activity summary"
        },
        {
          title: "Tax summary"
        },
        {
          title: "Unpaid invoices",
          comingSoon: true
        }
      ]
    },
    {
      title: "Customers and Subscriptions",
      lists: [
        {
          title: "Add-on and allowance take-up ",
          comingSoon: true
        },
        {
          title: "Allowance usage",
          comingSoon: true
        },
        {
          title: "Customer and subscription summary",
          comingSoon: true
        },
        {
          title: "Discount and coupon usage",
          comingSoon: true
        },
        {
          title: "Product, plan and cycle performance",
          comingSoon: true
        },
        {
          title: "Value score",
          comingSoon: true
        }
      ]
    },
    {
      title: "Checkout and self-service ",
      lists: [
        {
          title: "Billsby Checkout performance",
          comingSoon: true
        },
        {
          title: "Exit reasons",
          comingSoon: true
        }
      ]
    }
  ]
}

const shareData = {
  title: "Share data and insights with everyone on your team.",
  description: [
    {
      text: "Every Billsby account includes unlimited teammates - which means everyone that needs access to the Billsby Dashboard and our reporting features can have it at no extra cost to your company. "
    },
    {
      text: " No more manually collating reports and statistics - let everyone self-serve and get the data, insights and reporting they need instantly."
    }
  ],
  image: 'share-data.png',
  backgroundImage: require('../../../images/share-data.png'),
  imageName: 'share data',
  backgroundColor: '#243F5C',
  textColor: '#FFFFFF',
  imagePosition: 'full-right'
}

const reportsInsights = () => {
  return (
    <Layout className="reporting reports-insight">
      <SEO 
        title="Reports and Insight | Billsby | Powerful, customizable subscription billing software" 
        description="Dive deep with reporting and insights for your business from the most powerful, customizable and easy-to-integrate subscription billing software for recurring payments." 
        url="https://www.billsby.com/product/reporting/reports-and-insight"
      />

      <TwoColumn content={bannerContent}/>
      <Reports content={reportsContent}/>
      <TwoColumn content={billsbyBriefing}/>
      <CustomerJourney content={activitySummary} />
      <CustomerJourney content={taxSummary} />
      <CustomerJourney content={exitReasons} />
      <TwoColumn content={shareData}/>
    </Layout>
  )
}

export default reportsInsights