import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const Reports = ({content}) => {
  return (
    <div className="section-reports" style={{ backgroundColor: content.backgroundColor }}>
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <p className="description">{content.description}</p>
        <div className="button-wrapper">
          {content.buttons ?
            content.buttons.map((btn, i) => {
              return (
                <>
                {
                  btn.url
                  ?
                  btn.scheduleButton 
                   ?
                  <ScheduleADemoButton buttonColor={btn.buttonColor}/> 
                  :
                    btn.isExternal === true
                    ?
                    <a 
                      href={btn.url}
                      key={i}
                      target="_blank" rel="noopener noreferrer"
                      className={classNames('btn', 
                        {"btn-orange" : btn.buttonColor === "orange"},
                        {"btn-black" : btn.buttonColor === "black"},
                        {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}</a>

                    :
                    <Link 
                      to={btn.url}
                      key={i}
                      className={classNames('btn', 
                        {"btn-orange" : btn.buttonColor === "orange"},
                        {"btn-black" : btn.buttonColor === "black"},
                        {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}</Link>

                  :
                    <Link 
                    to={content.url}
                    key={i}
                    className={classNames('btn', 
                      {"btn-orange" : btn.buttonColor === "orange"},
                      {"btn-black" : btn.buttonColor === "black"},
                      {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}</Link>
                }
                </>
              )
            })
            :
            ""
          }
        </div>

        <div className="report-blocks">
          {content.blocks.map((block, i) => {
            return (
              <div className="list" key={i}>
                <h3>{ block.title }</h3>

                {block.lists.map((list, i) => {
                  return (
                    <div className="list-item" key={i}> 
                      <p className="list-item-title">{list.title} {list.comingSoon === true ? <span className="soon">Soon</span> : ""}</p>
                      
                    </div>
                  )
                })}


              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}

export default Reports